.array-container {
    position: relative;
    /* left: 100px; */
    /* justify-content: center; */
    background-color: #242424;
    /* min-height: 100%; */
    /* position: absolute; */
    display: flex;
    flex-flow: column wrap;
    /* align-items: center; */
    /* justify-content: center; */
    padding-top: 0px;
    /* margin: auto; */
    min-height: 100vh;
    /* justify-content: center; */
    align-content: center;
    /* justify-content: center; */
}

.bar-container {
    padding-top: 12px;
    padding-bottom: 48px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.sorting-buttons, .array-container > h1 {
    padding-top: 8px;
    position: relative;
    display: flex;
    flex-flow:row wrap;
    justify-content: center;
    /* align-content: center; */
}

.array-bar {
    width: 6px;
    display: inline-block;
    margin: 0 1px;
    text-align: center;
    font-weight: bold;
}

/* For slider styling */
#animation-speed-text , #animation-speed, #visualizer-header {
    margin: 0;
    padding: 4px 0px;
    color: #f0f0f0;
    text-align: center;
}

.slidecontainer {
    padding-top: 8px;
    width: 31vw;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 36px;
    background-color: #242424;
  }
  
.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    background: rgb(240, 255, 253);
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
.slider:hover {
    opacity: 1;
}
  
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    background: lightgreen;
    cursor: pointer;
}
  
.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: lightgreen;
    cursor: pointer;
}