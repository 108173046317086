.navbar-custom {
  background-color: #242424 !important;
}

.brand {
  font-family: "Permanent Marker", cursive;
  font-size: 24px;
}

.nav-link {
  font-size: 18px;
  margin-right: 24px;
  color: #f7f7f7 !important;
  /* font-weight: bold; */
  font-family: "Permanent Marker", cursive;
  letter-spacing: 1.5px;
}

.nav-link:hover {
  color: #1a97a9 !important;
}
.brand:hover {
  color: #1a97a9 !important;
}
