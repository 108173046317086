.calc-app {
  padding-top: 10%;
  height: 100vh;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  background: #242424;
  margin: -8px;
}

div {
  display: block;
}

.calc-container {
  border: 2px solid #242424;
  padding: 5px;
  background: black;
  width: 320px;
  position: relative;
  display: block;
  box-sizing: content-box;
}
.formula-screen {
  min-height: 20px;
  font-family: "Big Shoulders Stencil Text", cursive;
  font-size: 20px;
  color: rgb(201, 67, 67);
  text-align: right;
  line-height: 20px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  display: block;
}
.output-screen {
  font-size: 29px;
  font-family: "Big Shoulders Stencil Text", cursive;
  color: white;
  text-align: right;
  line-height: 35px;
  display: block;
}
.AC {
  background-color: rgb(170, 125, 57);
  width: 160px;
}
.zero {
  width: 160px;
}
.equals {
  background-color: rgb(43, 75, 111);
  position: absolute;
  height: 130px;
  bottom: 5px;
  display: inline-block;
  width: 80px;
  color: white;
  outline: 1px solid black;
  border: none;
  font-family: Share Tech Mono, monospace;
  font-size: 20px;
  cursor: default;
}

.times,
.divide,
.plus,
.minus {
  /* background: rgb(102, 102, 102); */
  background: rgb(34, 103, 103);
  background: rgb(22, 76, 103);
  background: rgb(38, 115, 86);
}

.calculator-link-button {
  width: 20%;
  text-align: center;
  margin: auto;
}

/* Start Media */
@media only screen and (max-width: 800px) {
  .calculator-link-button {
    width: 35%;
  }
}

@media only screen and (max-width: 450px) {
  .calculator-link-button {
    width: 50%;
  }
}
