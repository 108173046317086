.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-wrapper {
  flex: 1;
  min-height: 80vh;
  background-color: #242424;
  color: #f9f9f9;
}

.about-text {
  text-align: center;
  font-family: "Permanent Marker", cursive;
  max-width: 50%;
  margin: auto;
  padding: 72px 0 16px 0;
  line-height: 200%;
}

.interests-text {
  text-align: center;
  font-family: "Permanent Marker", cursive;
  max-width: 50%;
  margin: auto;
  padding: 16px 0 16px 0;
  line-height: 200%;
}

#about-hr {
  background-color: #f9f9f9;
}

.about-text > p,
.interests-text > p {
  font-family: sans-serif, "Trebuchet MS", "Lucida Sans Unicode",
    "Lucida Grande", "Lucida Sans", Arial, sans-serif;
}

.about-contact {
  font-family: sans-serif, "Trebuchet MS", "Lucida Sans Unicode",
    "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  text-align: center;
  margin: auto;
  max-width: 50%;
  padding: 16px 0 56px 0;
  line-height: 200%;
}

@media only screen and (max-width: 1050px) {
  .about-text,
  .about-container,
  .about-text,
  .interests-text,
  .about-contact {
    padding: 36px 0 12px 0;
    max-width: 75%;
    line-height: 200%;
  }
}
