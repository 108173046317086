/* 
########################################################
########################################################
************** GRAPHVIZ STYLING OVERRIDES **************
########################################################
########################################################
*/

:root {
  --sigma-background-color: #fff;
  --sigma-controls-background-color: #fff;
  --sigma-controls-background-color-hover: rgba(0, 0, 0, 0.2);
  --sigma-controls-border-color: rgba(0, 0, 0, 0.2);
  --sigma-controls-color: #000;
  --sigma-controls-zindex: 100;
  --sigma-controls-margin: 5px;
  --sigma-controls-size: 45px; /* 30px*/
}

div.react-sigma {
  height: 100%;
  width: 100%;
  position: relative;
  background: var(--sigma-background-color);
}
div.sigma-container {
  height: 100%;
  width: 100%;
}

/**
 * Sigma controls wrapper
 */
.react-sigma-controls {
  position: absolute;
  z-index: var(--sigma-controls-zindex);
  border: none;
  color: var(--sigma-controls-color);
  background-color: var(--sigma-controls-background-color);
}
.react-sigma-controls.bottom-right {
  bottom: var(--sigma-controls-margin);
  right: var(--sigma-controls-margin);
}
.react-sigma-controls.bottom-left {
  bottom: var(--sigma-controls-margin);
  left: var(--sigma-controls-margin);
}
.react-sigma-controls.top-right {
  top: var(--sigma-controls-margin);
  right: var(--sigma-controls-margin);
}
.react-sigma-controls.top-left {
  top: var(--sigma-controls-margin);
  left: var(--sigma-controls-margin);
}
.react-sigma-controls:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.react-sigma-controls:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/**
 * Sigma control button
 */
.react-sigma-control {
  width: var(--sigma-controls-size);
  height: var(--sigma-controls-size);
  margin: 0;
  line-height: var(--sigma-controls-size);
  background-color: var(--sigma-controls-background-color);
  border-bottom: 1px solid var(--sigma-controls-border-color);
}
.react-sigma-control:hover {
  border: none;
}
.react-sigma-control:last-child {
  border-bottom: none;
}

.react-sigma-control > * {
  box-sizing: border-box;
}

.react-sigma-control > button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  margin: 0 auto;
  padding: 0;
  width: var(--sigma-controls-size);
  height: var(--sigma-controls-size);
  line-height: var(--sigma-controls-size);
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  background-color: var(--sigma-controls-background-color);
  clip: rect(0, 0, 0, 0);
}
.react-sigma-control > button:hover {
  background-color: var(--sigma-controls-background-color);
  border: none !important;
  outline: none !important;
  color: rgba(0, 0, 0, 0.3);
}

/**
 * Search style
 */
.react-sigma-search {
  background-color: var(--sigma-controls-background-color);
  border: 2px solid var(--sigma-controls-border-color);
}
.react-sigma-search label {
  visibility: hidden;
}
.react-sigma-search input {
  color: var(--sigma-controls-color);
  background-color: var(--sigma-controls-background-color);
  font-size: 1em;
  width: 100%;
  margin: 0;
  border: none;
  padding: 5;
  box-sizing: border-box;
}
