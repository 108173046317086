.stock-chart-container {
    position: relative;
    display: flex;
    flex-flow:column wrap;
    margin: auto;
    padding: 25px 45px;
    border-radius: 8px;
    width:100% !important;
    height:100% !important;
}
.crypto-container {
    background-color: #242424;
    margin: auto;
    min-height: 100vh;
    padding-bottom: 50px;
}

.stock-chart-forms {
    color: #f9f9f9;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: auto;
    padding: auto;
    width:80% !important;
    height:100% !important;
    text-align: center;
}

.crypto-profit {
    color: #f9f9f9;
    position: relative;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 50px auto auto auto;
    background: #303030;
    border-radius: 16px;
    padding: 25px 0px;
    width:70% !important;
    height:100% !important;
}

.crypto-profit-title {
    color: #0E98AB;
    text-align: center;
}

.crypto-profit-gains {
    padding-top: 10px;
    font-weight: bold;
    text-align: center;
}

@media only screen and (max-width: 450px) {
    .stock-chart-container {
        padding: 10px 10px;
    }
    .crypto-profit {
        margin-top: 8% !important;
        border-radius: 8px;
        padding: 2% 4%;
        width:90% !important;
        height:100% !important;
    }
}