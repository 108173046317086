.footer-container {
  background-color: #242424;
  padding: 2rem 0 4rem 0;
  display: flex;
}

.footer-wrapper {
  flex: 1;
  flex-direction: column;
}

/* Contact */
.footer-contact-container {
  display: flex;
  justify-content: center;
  margin: 16px auto 26px auto;
  width: 360px;
}

.footer-contact-button {
  font-size: 20px;
}

/* Social */

.social-icons-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 360px;
}

.social-icon-link {
  color: #fff;
  font-size: 36px;
}

.social-icon-link:hover {
  color: #1a97a9 !important;
}

/* Website Rights */
.website-rights-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #fff;
  margin-top: 16px;
  margin-bottom: 16px;
}

@media only screen and (max-width: 400px) {
  .footer-container {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
  .footer-wrapper {
    flex: 1;
    width: 100%;
  }
  .footer-contact-container {
    justify-content: center;
    margin: 0px auto 16px auto;
    width: 100px;
  }
  .social-icons-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin: 0px auto 0px auto;
    width: 340px;
  }
  .social-icon-link {
    font-size: 28px;
  }

  .website-rights {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: auto;
  }
}
